import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { withAttrs } from '../../../utils'
import { mediaQueries } from '../../../theme'
import Link from '../../shared/link'

const moveToRight = keyframes`
  0% {
    transform: translate3d(-50%,0,0);
  }

  100% {
    transform: translateZ(0);
  }
`

const Container = styled.div`
  background-image: linear-gradient(
    135deg,
    rgba(255, 216, 76, 50%) 0%,
    rgba(212, 161, 214, 50%) 52.85%,
    rgba(129, 212, 255, 50%) 100%
  );
  overflow: hidden;
`

const MainText = styled.span``

const LineLink = styled(withAttrs(Link, { variant: 'nav' }))`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 200vw;
  width: max-content;
  min-height: 7.5rem;
  padding: ${p => p.theme.space[11]} 0;
  font-size: ${p => p.theme.fontSizes[5]};
  line-height: 1.2;
  font-weight: ${p => p.theme.fontWeights.bold};
  color: ${p => p.theme.colors.black[1]};
  transform: translate3d(-50%, 0, 0);
  animation: ${moveToRight} 35s infinite linear;

  &:hover,
  &:focus {
    text-decoration: none;

    ${MainText} {
      text-decoration: underline;
    }
  }

  ${mediaQueries.lg} {
    padding: ${p => p.theme.space[14]} 0;
    font-size: ${p => p.theme.fontSizes[9]};
  }
`

const Arrow = styled.span`
  padding: 0 ${p => p.theme.space[5]};
`

const Text = styled.p`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  min-width: 50%;
  margin: 0;
`

const Fit = styled.span`
  display: flex;
`

const RunningLine = ({ link = '/start', children, className }) => {
  const count = [1, 2]
  const texts = new Array(6).fill(children, 0, 4)

  return (
    <Container className={className}>
      <LineLink to={link}>
        {count.map((item, index) => (
          <Text key={`block-${index}`}>
            {texts.map((text, index) => (
              <Fit key={`text-repeat-${index}`}>
                <MainText>{text}</MainText> <Arrow>→</Arrow>
              </Fit>
            ))}
          </Text>
        ))}
      </LineLink>
    </Container>
  )
}

export default RunningLine
